import $ from 'jquery';
import magnificPopup from "magnific-popup";


$(document).ready(function(){ 
    myConnections();
    myConnectionsStatic();
    $("#user-search-status").on("click", myConnectionsStatic); 
}); //document ready ends

window.myConnectionsStatic = function(){
     
     if($('.js_modal').length > 0){
		$('.js_modal').magnificPopup({
			type: 'inline',
			closeBtnInside: true
		});
      }
      
      if($('.js_click_me2').length > 0){ //for curency selection modal
        
		$('.js_click_me2').on('click', function () {
			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-currency'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  
	  if($('.js_click_me_t_zone').length > 0){ //for curency selection modal
        
		$('.js_click_me_t_zone').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-zone'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  
	  if($('.js_click_me3').length > 0){ //for updates modal
		$('.js_click_me3').on('click', function () {
			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-updates'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  
	  if($('.js_click_me4').length > 0){ //for language
		$('.js_click_me4').on('click', function () {
			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-locale'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: false,
				modal: true
			});
		});
	  };
	  

	  
	  if($('.js_click_me5').length > 0){ //for object create update
		$('.js_click_me5').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: '#modal-edit-object'
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: true,
				modal: true
			});
		});
	  };
	  
	   if($('.js_click_me_uni').length > 0){ //for object create update
		$('.js_click_me_uni').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
			$.magnificPopup.open({
				items: {
					src: ('#'+ $(this)[0].dataset.target)
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: true,
				modal: true
			});
		});
	  };
	  
	  	  if($('.js_click_non_close').length > 0){ //for modals that should not close 
	    	$('.js_click_non_close').on('click', function () {
// 			$('.fix-card-link').addClass('visible');
            	$.magnificPopup.open({
				items: {
					src: $(this).attr('href')
				},
				type: 'inline',
				closeBtnInside: true,
				showCloseBtn: true,
				modal: true
			});
   
 
		});
	  };
	  
      avatarColor();
      
     urlifyComment();
     
 };
 
window.avatarColor = function() {
    
      if($('.no-avatar-present').length > 0){
        $('.no-avatar-present').each(function(){
          var name = $(this)[0].dataset.name;
          var color = stringToHslColor(name, 30, 70);
          $(this).css({"background-color" : color});
        });
      };
      
      function stringToHslColor(str, s, l) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        };
        var h = hash % 360;
        return 'hsl('+h+', '+s+'%, '+l+'%)';
      };
      
};


window.urlifyComment = function() {
  // urlify comment
  if ($(".comment-just-body").length > 0) {
    $(".comment-just-body:not(.urlified)").each(function () {
      var body = $(this).html();
      $(this).html(window.urlifyComment.urlify(body));
      $(this).addClass("urlified");     //to avoind double urlification
    });
  }
  $(".comment-wrap .trix-content a").attr("target", "_blank"); //for trix links
}

window.urlifyComment.urlify = function(text) {
  // var urlRegex = /(https?:\/\/[^\s]+)/g;
  var urlRegex = /(?=(([^"]+"){2})*[^"]*$)(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  //  (?=(([^"]+"){2})*[^"]*$)((https?:\/\/)?(\w+\.)+\w{2,}(:[0-9]+)?((\/\w+)+(\.\w+)?)?\/?)
  return text.replace(urlRegex, function (url) {
    var domain = new URL(url).hostname;
    if (domain == "www.loom.com") {
      var match = url.match(/https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9]+)/);
      return `<div style="position: relative; padding-bottom: 60.56971514242879%; height: 0;"><iframe src="https://www.loom.com/embed/${match[1]}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`
    } else if (domain == "www.youtube.com" || domain == "www.youtu.be") {
      var match = url.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:\.be\/|be\.com\/watch\?v=)([a-zA-Z0-9_-]{11})/);
      return `<div class="video"><iframe width="560" height="315" src="https://www.youtube.com/embed/${match[1]}?" title="YouTube" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`
    } else if (domain == "vimeo.com") {
      var match = url.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
      var locale = navigator.language || navigator.userLanguage;
      return `<div class="video"><iframe src="https://player.vimeo.com/video/${match[1]}?texttrack=${locale}&byline=0&title=0&controls=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>`
    } else {
      // no video, just text link
      return '<a href="' + url + '" target="_blank">' + url.substring(0, 35) + '...</a>';
    }
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}
   
    
function myConnections() {
    
    
    
    //copy link in invite modal
    if($('.copy-invitation-link').length > 0){
        $(document).on("click", ".copy-invitation-link", function(){
            
         var parent = $(this).parents(".invite-link");
         var copyText = parent.find(".invitation-link")[0];
            
         // var copyText = $(".invitation-link")[0];
          var afterText = parent.find(".copy-btn-text");
          /* Select the text field */
          copyText.select(); 
          copyText.setSelectionRange(0, 99999); /*For mobile devices*/
          
          /* Copy the text inside the text field */
          document.execCommand("copy");
          
          afterText.html(afterText[0].dataset.afteraction);
        });
    };
    
    
    //hiding invitation from my-connections
 
      $(document).on("click", '.hide-invitation', function(){
          console.log('gidee!');
          var element = $(this);
          var originalText = $(this).html();
          $(this).html("<span class='fa fa-spinner fa-spin'> </span>");
          var parent = $(this).parents("tr");
          
          $.ajax({
          url: "/hide_invitation?id="+$(this)[0].dataset.id,
          type: "GET",
          success: function(data) {
            parent.hide();
          },
          error: function(data) {
            element.html(originalText);
            alert("could not hide this invitation, please try later");
          },
        });
      });
 
    
    //resending-invitation from my-connections table
 
        $(document).on("click", '.resend-invitation', function(){
        console.log('gidee!');
         var element = $(this);
         var originalText = $(this).html();
         $(this).html("<span class='fa fa-spinner fa-spin'> </span>");
         var parent = $(this).parents("tr");
         var timeStamp = parent.find(".invited-when");
         var link = $(this)[0].dataset.practitioner == "true" ? "/send_invitation_to_colleague" : "/send_invitation_to_patient"
         
         $.ajax({
          url: link + "?name="+$(this)[0].dataset.name + "&receiver=" + $(this)[0].dataset.email + "&sender_name=" + $(this)[0].dataset.sender + "&sender_email=" + $(this)[0].dataset.senderEmail + "&resending=true",
          type: "POST",
          success: function(data) {
           timeStamp.html(element[0].dataset.afterText);
           element.html(originalText);
          },
          error: function(data) {
            element.html(originalText);
            alert("could not resend the invitation, please try later");
          },
        });
      });
   
    
    
      //seding request manupulations with the modal info
      if( $(".request-testimonial").length > 0 ){
          var sendBtn = $("#modal-confirm-feedback a");
          var originalText = sendBtn.html();
             
          $(document).on("click", '.request-testimonial', function(){
             var name = $(this)[0].dataset.name;
             var id = $(this)[0].dataset.id;
             var link = 'request_testimonial/?id=' + id
             $('#modal-confirm-feedback #name-testimonial').html(name);
             sendBtn.removeClass("disabled");
             sendBtn.html(originalText);
             sendBtn.attr({'href': link });
             sendBtn.on("click", function(){
                var afterText = $(this)[0].dataset.afteraction;
                $(this).html(afterText);
                $(this).addClass("disabled");
             });
             
          });
      };
      
      
      
       //provisioning sub for the client with the modal info
    //   if( $(".provision-sub").length > 0 ){
    //       var sendBtn2 = $("#modal-provision-sub a");
    //       var originalText2 = sendBtn2.html();
             
    //       $(document).on("click", '.provision-sub', function(){
           
    //          var name = $(this)[0].dataset.name;
    //          var id = $(this)[0].dataset.id;
    //          var link = 'provision_subscription/?id=' + id;
    //          $('#modal-provision-sub #name-premium').html(name);
    //          sendBtn2.removeClass("disabled");
    //          sendBtn2.html(originalText2);
    //          sendBtn2.attr({'href': link });
    //          sendBtn2.on("click", function(){
    //             $('.spinner-provisions').show();
    //             $('.number-provisions').hide();
    //             var afterText = $(this)[0].dataset.afteraction;
    //             $(this).html(afterText);
    //             $(this).addClass("disabled");
    //          });
             
    //       });
    //   };
      
        //demoting sub for the client with the modal info
      if( $(".demote-sub, .provision-sub").length > 0 ){
          
             
          $(document).on("click", '.demote-sub, .provision-sub', function(){
             var id = $(this)[0].dataset.id;
             if ($(this).hasClass( "demote-sub" )){
                var sendBtn3 = $("#modal-demote-sub a.btn");
                var link = 'demote_subscription/?id=' + id
             } else if ($(this).hasClass( "provision-sub" )){
                var sendBtn3 = $("#modal-provision-sub a.btn"); 
                var link = 'provision_subscription/?id=' + id
                var trial = parseInt($(this)[0].dataset.trial);
                if (trial > 0){
                    $("#trial_time_info").show();
                    $("#trial_days_modal").html(trial);
                }else{
                    $("#trial_time_info").hide();
                };
             };
             var originalText3 = sendBtn3[0].dataset.beforeaction;
             var name = $(this)[0].dataset.name;
             var id = $(this)[0].dataset.id;
             var dateEnd = $(this)[0].dataset.end;
             
             $('#modal-demote-sub #name-premium, #modal-provision-sub #name-premium').html(name);
             $('#modal-demote-sub #end-date').html(dateEnd);
             sendBtn3.removeClass("disabled");
             sendBtn3.html(originalText3);
             sendBtn3.attr({'href': link });
             sendBtn3.on("click", function(){
                $('.spinner-provisions').show();
                $('.number-provisions').hide();
                var afterText = $(this)[0].dataset.afteraction;
                $(this).html(afterText);
                $(this).addClass("disabled");
             });
             
          });
      };
};