import consumer from "./consumer"

// TODO:
// check for data.chat_id and current opened chat

$(function() {
  $('[data-channel-subscribe="chat-user"]').each(function(index, element) {
    console.log("chat-user-channel js loaded");

    var $element = $(element),
        user_id  = $element.data('user-id');

    consumer.subscriptions.create(
      {
        channel: "ChatUserChannel",
        user_id: user_id
      },
    {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        console.log('=========== received =========');
        console.log(data);
        if (data.type === 'new_message') {
          window.receiveMessage(data.message);
        } else if (data.type === 'edited_message') {
          window.receiveEditedMessage(data.message);
        } else if (data.type === 'message_reaction') {
          window.receiveMessageReaction(data.reaction);
        } else if (data.type === 'chat_user_activity') {
          window.receiveChatUserActivity(data.chat_user_activity);
        } else if (data.type === 'archived_message') {
          window.receiveArchivedMessage(data.message)
        } else if (data.type === 'chat_event') {
          window.receiveChatEvent(data.event);
        } else if (data.type === 'change_chat_users') {
          window.receiveChangeChatUsers(data);
        }
      }
    });
  });
});
