import Swiper from "swiper";
import magnificPopup from "magnific-popup";
import autosize from "autosize";
// import jcf from "jcf";
import $ from 'jquery';

import tippy from 'tippy.js';

 
 

        
$(document).ready(function(){ 
  
  
  // pay for order bing click
  if($("#pay-for-order").length > 0){
  $(document).on("click", "#pay-for-order", function(){
   //$('#pay-for-order').on('click', function() {
       $('#pay-card-button').click();
    });
  };
  
  //show practice from map
  $(document).on("click", ".practice-name-map", function(){
    var id = $(this)[0].dataset.id;
    var link = $("#link_practitioner").attr("href")
    $("#link_practitioner").attr("href", link + id);
    $("#link_practitioner").click();
    $("#link_practitioner").attr("href", link);
  });
  
  //show testimonials in practices
  $(document).on("click", ".show_testimonials", function(){
    var testimonials = $(".index-reviews");
    if(!testimonials.is(':visible')){
      testimonials.show(200);
      setTimeout(initSliders, 220);
    }
  });
  
  //like comment
  $(document).on("click", ".spin-on-click", function(){
    var pic = $(this).find(".pic-light, .pic-dark");
    pic.html("<i class='fa fa-spinner fa-spin like-spinner'  ></i>");
    
  });
  
 
  
   //open faq results
  $(document).on("click", ".faq-question h3", function(){
     
      var link = $(this);
      var hold = link.parent(".faq-question")
	  	var text = hold.find('.in');
			text.slideToggle(200, function () {
				hold.toggleClass('open');
			});
    
  });
  

  
  
  
  
  
  //comments translations
  if($(".post-comments").length > 0){
    $(document).on("click", ".translation-original", function(){
        var parent = $(this).parents(".comment-text, .message-body");
        var commentBody = parent.find(".comment-body");
        commentBody.toggle();
    });
  };
  
  //testimonials translations
  if($(".ic-testimonial-body").length > 0 || $(".grid-people-el").length > 0){
    $(document).on("click", ".translation-original", function(){
        var parent = $(this).parents(".ic-testimonial-body");
        var commentBody = parent.find(".testimonial-body");
        commentBody.toggle();
    });
  };
  
  
  //open button controls for ICs and complexes
  $(document).on("click", ".more-menu-toggle", function() {
       if (!$(this).hasClass('controls-loaded')){
         var id = $(this)[0].dataset.id
         var model = $(this)[0].dataset.model
         var posi = $(this)[0].dataset.posi
         $.ajax({
           url: "/load_control_buttons?model=" + model + "&id=" + id + "&posi="+posi,
           type: "GET",
           success: function(response) {
              $(`.more-menu-toggle[data-id="${id}"][data-model="${model}"]`).parent().find(".js_modal").magnificPopup({
            	type: 'inline',
        		closeBtnInside: true
          	  });
           },
         })
         $(this).addClass("controls-loaded");
       }
     
    });
    
   //open button controls for comments and testimonials
   $(document).on("click", ".menu-actions", function() {
       
 
      if (!$(this).hasClass('controls-loaded')){
         var id = $(this)[0].dataset.id
         var model = $(this)[0].dataset.model
         var level = $(this)[0].dataset.level
         $.ajax({
          url: "/load_comment_controls?model=" + model + "&id=" + id + "&level="+level,
          type: "GET"
         })
         $(this).addClass("controls-loaded");
      }
     
    });
    
    
  
  $(document).on("click", ".copy-to-clipdoard", function() {
   var text = $(this)[0].dataset.text;
   var message = $(this)[0].dataset.message;
   copyToClipboard(text);
   $("#action-notification-message")[0].innerHTML = message;
   $('#action-notification-block').addClass('open');
   setTimeout(function () {
      $('#action-notification-block').removeClass('open');
    }, 5000);
  });
  
    
  //filters index page
  if($(".has_cheched_filter").length > 0){
    $(".has_cheched_filter").each(function(){
      var parent = $(this).parents(".filters-section");
      parent.addClass("is-selected");
    });
  };
  
  //add spiner when click on filter
  
  if($(".spinner-activator").length > 0){
      
    $(document).on("click", ".spinner-activator", function(){
      $(this).replaceWith("<span><i class='fas fa-spinner fa-spin'></i> </span>")
    //  $(".catalog-page-content")[0].replaceWith("<span><i class='fas fa-spinner fa-spin'></i> </span>")
    });
  };
  
  
  if($('.go-back-btn').length > 0){
      $('.go-back-btn').on('click', function(){
  
         if (window.history.length > 2){
           window.history.back();
         }else{
           window.location.href = $(this)[0].dataset.fallbackUrl;
         };
      });
   };
    
  if($('.submit-form-btn').length > 0){
    $('.submit-form-btn').on('click', function(event){
       event.preventDefault();
       $('.submit-form-btn').addClass('disabled');
       $('.submit-form-btn').html("<i class='fa fa-spinner fa-spin '  ></i> "+$('.submit-form-btn')[0].dataset.originalText);
       setTimeout( function(){
         $('form').first().submit();
       }
       , 200);
     });
   };
   
   if($('.submit-form-btn2').length > 0){
    
    var fields = [".ic-start-at-field", ".ic-duration-field", ".ic-freq-field"]
    
    $(document).on("change", ".field_with_errors", function(){
     $(this).removeClass("field_with_errors");
    });
    
    
    $('.submit-form-btn2').on('click', function(event){
       event.preventDefault();
       var well = true;
       fields.forEach(function(type){
         var els = $(type+":visible");
         var first = false;
         for (var i = 0; i < els.length; i++){
           if (!els[i].value){
             els[i].classList.add("field_with_errors")
             well = false;
             if (!first){
               first = true;
               els[i].focus();
             }
           };
         };
       });
       
       if (well){
         $('.submit-form-btn2').addClass('disabled');
         $('.submit-form-btn2').html("<i class='fa fa-spinner fa-spin '  ></i> "+$('.submit-form-btn2')[0].dataset.originalText);
 
         setTimeout( function(){
           $('.add-ic-mainblock form').submit();
         }
         , 200);
       }else{
         
       };
       
     });
   };
   
   //adding http inforn of address if needed
   if($('.url-form-field').length > 0){
    $('.url-form-field').on('change', function(event){
      var url = $(this).val();
      if (!/^https?:\/\//i.test(url) && url != '' ) {
        $(this).val('https://' + url);
      }
     });
   };
   
   $(window).on('popstate', function() {
      if($('.submit-form-btn').length > 0){
        $('.submit-form-btn').removeClass('disabled');
        $('.submit-form-btn').html($('.submit-form-btn')[0].dataset.originalText);
      };
      
   });
   
 
  
  $(window).bind("pageshow", function(event) {
    if($('.submit-form-btn').length > 0){
        $('.submit-form-btn').removeClass('disabled');
        $('.submit-form-btn').html($('.submit-form-btn')[0].dataset.originalText);
     };
  });
  
  if($(".stripe-button-el").length > 0){
      var btn = $(".stripe-button-el");
     // btn.hide();
      $(".chance-payment-card-btn").on("click", function(event) {
         btn.click();
      });
      
  };
  
  
  $("body").on("submit", "form#new_comment",  function(){
        var input = $(this).find(".comment-edit-area").val();
        if(input != ''){
          $(".submit-comment .fa-spin").show();
          $(".submit-comment .submit-comment-icon").hide();
        }
  });
  
  
  //img previews
  if($("#image-upload").length > 0){
      $("#image-upload").on("change", function() {
          
          if ($(this)[0].files && $(this)[0].files[0]) {
              console.log("change");
            var reader = new FileReader();
            reader.onload = function (e) {
              document.getElementById("img_prev").style.display = "block";
              if ($("#next-btn-avatar").length > 0){
                document.getElementById("next-btn-avatar").disabled = false;
              };
              $('#img_prev')
                .attr('src', e.target.result)
                .height(auto);
            };
        
            reader.readAsDataURL($(this)[0].files[0]);
          }
      })
  };
  

  
  
//   function previewImg(input) {
//       if (input.files && input.files[0]) {
//         var reader = new FileReader();
//         reader.onload = function (e) {
//           document.getElementById("img_prev").style.display = "block";
//           document.getElementById("next-btn-avatar").disabled = false;
//           $('#img_prev')
//             .attr('src', e.target.result)
//             .height(auto);
//         };
    
//         reader.readAsDataURL(input.files[0]);
//       }
//     };

    
   if($(".form-add-review").length > 0){
      
     $(document).on("click", ".wrap-stars .review-rating", function(){
        $("#review-part2").show(300);
     //  $(".catalog-page-content")[0].replaceWith("<span><i class='fas fa-spinner fa-spin'></i> </span>")
     });
   };
 
   

      $(document).on('click','.see-more-html', function(e) {
        e.preventDefault()
        // var truncated = 
        $(this).closest('.truncated-body-html').hide();
        // var fullbody = 
        $(this).closest('.tes-body').find(".full-body-html").show();
        // $(this).parents(".tes-body").html(body)
      });
 
  
   if($(".ic-rep-value").length > 0){
      $('.ic-rep-value').on('change', function() {
          
        var ic_id = $(this)[0].dataset.icid;
        $("#ic-item"+ic_id)[0].dataset.repeatleft = $(this)[0].value;
        $("#ic-item"+ic_id)[0].dataset.repeat = $(this)[0].value;
        
        if( $(this)[0].dataset.owner == "yes"){
         $.ajax({
          url: '/change_ics_repeat' + "?id="+$(this)[0].dataset.id + "&ic_id=" + $(this)[0].dataset.icid + "&ic_repeats=" + $(this)[0].value,
          type: "POST",
        //   success: function(data) {
        //   timeStamp.html(element[0].dataset.afterText);
        //   element.html(originalText);
        //   },
        //   error: function(data) {
        //     element.html(originalText);
        //     alert("could not resend the invitation, please try later");
        //   },
        });  
        //then change value of repeat on IC item
        };
      });
    
  
    };
   
   
   if($(".click-save").length > 0){
      $(document).on("click", ".click-save", function(){
        copyToClipboard($(this).html()); 
         $(this).addClass("red")
         $(this).animate({
            opacity: 0.25,
          }, 200, function() {
             $(this).animate({
                opacity: 1,
              }, 400, function() {
                // Animation complete.
              });
          });
      });
   }
   
   if($(".mark-to-ship").length > 0){
      $(document).on("click", ".mark-to-ship", function(){
     
        copyToClipboard($(this).html()); 
         $(this).toggleClass("green");
         $(this).animate({
            opacity: 0.25,
          }, 200, function() {
             $(this).animate({
                opacity: 1,
              }, 400, function() {
                // Animation complete.
              });
          });
         $("#orders-count").html($(`.mark-to-ship.green`).length );
      });
   }
  
  function copyToClipboard(text) {
   const elem = document.createElement('textarea');
   elem.value = text;
   document.body.appendChild(elem);
   elem.select();
   document.execCommand('copy');
   document.body.removeChild(elem);
  }
  
  
});




 